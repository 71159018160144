/**
 * Internal
 */
import InternalForm from '@components/FormElement/Form/Form';
import Label from '@components/FormElement/Form/Label';
import SubmitButton from '@components/FormElement/Form/SubmitButton';
import withControlled from '@components/FormElement/Form/withControlled';

const RangeInput = () => import('@components/FormElement/RangeInput');
const Input = () => import('@components/FormElement/Inputs/Input');
const Textarea = () => import('@components/FormElement/Inputs/Textarea');
const NumberInput = () => import('@components/FormElement/Inputs/NumberInput');
const UrlInput = () => import('@components/FormElement/Inputs/UrlInput');
const TimeInput = () => import('@components/FormElement/Inputs/TimeInput');
const EmailInput = () => import('@components/FormElement/Inputs/EmailInput');
const Select = () => import('@components/FormElement/Select');
const Switch = () => import('@components/FormElement/Switch');
const Radio = () => import('@components/FormElement/Radio');
const PlatformRouteInput = () => import('@components/FormElement/PlatformRouteInput');
const SolutionSelect = () => import('@components/FormElement/SolutionSelect');
const LanguageSelect = () => import('@components/FormElement/LanguageSelect');
const UserSelect = () => import('@components/FormElement/UserSelect');
const Selector = () => import('@components/FormElement/Selector');
const CheckboxGroup = () => import('@components/FormElement/CheckboxGroup');
const ColorPicker = () => import('@components/FormElement/ColorPicker');
const List = () => import('@components/FormElement/Lists/List');
const AdvancedList = () => import('@components/FormElement/Lists/AdvancedList');
const Watermarks = () => import('@components/FormElement/Watermarks');
const TagsSelect = () => import('@components/FormElement/TagsSelect');
const VideoLimits = () => import('@components/FormElement/VideoLimits');
const TemplatedText = () => import('@components/FormElement/TemplatedText');
const ManualCrop = () => import('@components/FormElement/ManualCrop');
const SelectCard = () => import('@components/FormElement/SelectCard');
const DateInput = () => import('@components/FormElement/DatePickers/DateInput');
const TimestampInput = () => import('@components/FormElement/DatePickers/TimestampInput');
const CustomDesign = () => import('@components/FormElement/CustomDesign');
const CodeEditor = () => import('@components/FormElement/CodeEditor');
const GranteeSelect = () => import('@components/FormElement/GranteeSelect');
const UploadInput = () => import('@components/FormElement/UploadInput');
const Cards = () => import('@components/FormElement/Cards');
const PhoneInput = () => import('@components/FormElement/PhoneInput');
const StateSelect = () => import('@components/FormElement/StateSelect');
const CountrySelect = () => import('@components/FormElement/CountrySelect');
const AddressAutocomplete = () => import('@components/FormElement/AddressAutocomplete');
const Multiselect = () => import('@components/FormElement/Multiselect');

export const Form = InternalForm;

Form.SubmitButton = SubmitButton;
Form.Label = Label;
Form.UserSelect = withControlled(UserSelect);
Form.Select = withControlled(Select);
Form.Selector = withControlled(Selector);
Form.Input = withControlled(Input);
Form.RangeInput = withControlled(RangeInput);
Form.Textarea = withControlled(Textarea);
Form.NumberInput = withControlled(NumberInput);
Form.EmailInput = withControlled(EmailInput);
Form.UrlInput = withControlled(UrlInput);
Form.TimeInput = withControlled(TimeInput);
Form.Switch = withControlled(Switch);
Form.Radio = withControlled(Radio);
Form.PlatformRouteInput = withControlled(PlatformRouteInput);
Form.SolutionSelect = withControlled(SolutionSelect);
Form.LanguageSelect = withControlled(LanguageSelect);
Form.CheckboxGroup = withControlled(CheckboxGroup);
Form.ColorPicker = withControlled(ColorPicker);
Form.List = withControlled(List);
Form.AdvancedList = withControlled(AdvancedList);
Form.TagsSelect = withControlled(TagsSelect);
Form.SelectCard = withControlled(SelectCard);
Form.Watermarks = withControlled(Watermarks);
Form.VideoLimits = withControlled(VideoLimits);
Form.ManualCrop = withControlled(ManualCrop);
Form.TemplatedText = withControlled(TemplatedText);
Form.DateInput = withControlled(DateInput);
Form.TimestampInput = withControlled(TimestampInput);
Form.CustomDesignEditor = withControlled(CustomDesign);
Form.CodeEditor = withControlled(CodeEditor);
Form.GranteeSelect = withControlled(GranteeSelect);
Form.UploadInput = withControlled(UploadInput);
Form.Cards = withControlled(Cards);
Form.PhoneInput = withControlled(PhoneInput);
Form.StateSelect = withControlled(StateSelect);
Form.CountrySelect = withControlled(CountrySelect);
Form.AddressAutocomplete = withControlled(AddressAutocomplete);
Form.Multiselect = withControlled(Multiselect);

export { default as useForm } from './useForm';
