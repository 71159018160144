import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { countryToLanguages } from '@app/translations';
import CreateTranslationSubtitleForm from '@components/Form/CreateTranslationSubtitleForm';
import Window from '@components/Window/Window';

const CreateTranslationSubtitleWindow = ({ subtitles = [], onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);

  const submitForm = (from, to) => {
    const existedSubtitles = subtitles.find(({ language }) => countryToLanguages[from]?.includes(language));

    if (!existedSubtitles) {
      setError( t('Error happened: failed to translate from {{from}} language code', { from }));
      return;
    }

    onSubmit(existedSubtitles.id, to);
  }

  return (
    <Window title={t('Translate subtitles')} open={open} onClose={onClose}>
      <CreateTranslationSubtitleForm subtitles={subtitles} onSubmit={submitForm} isLoading={isLoading} error={error} />
    </Window>
  );
};

export default CreateTranslationSubtitleWindow;
