import { createContext, useState, useContext, useMemo } from 'react';

import AddNewChangeLog from '@components/Window/AddChangeLogWindow';
import AddMediasWindow from '@components/Window/AddMediasWindow';
import AddMediaWindow from '@components/Window/AddMediaWindow';
import AddressWindow from '@components/Window/AddressWindow';
import AssignRole from '@components/Window/AssignRoleWindow';
import AuditTaskOverviewWindow from '@components/Window/AuditTaskOverviewWindow';
import AuditTaskWindow from '@components/Window/AuditTaskWindow';
import BasicWindow from '@components/Window/BasicWindow';
import CardsWindow from '@components/Window/CardsWindow';
import CommentFeelingWindow from '@components/Window/CommentFeelingWindow';
import CommentWindow from '@components/Window/CommentWindow';
import ConfirmationWindow from '@components/Window/ConfirmationWindow';
import ContractWindow from '@components/Window/ContractWindow';
import CreateBrowserWorkerWindow from '@components/Window/CreateBrowserWorkerWindow';
import CreateCardsWindow from '@components/Window/CreateCardsWindow';
import CreateCompanyWindow from '@components/Window/CreateCompanyWindow';
import CreateGroupWindow from '@components/Window/CreateGroupWindow';
import CreatePolicyWindow from '@components/Window/CreatePolicyWindow';
import CreatePresetWindow from '@components/Window/CreatePresetWindow';
import CreateQRCodeTemplateWindow from '@components/Window/CreateQRCodeTemplateWindow';
import CreateSocialMediaVideo from '@components/Window/CreateSocialMediaVideo';
import CreateSolutionRouteWindow from '@components/Window/CreateSolutionRouteWindow';
import CreateSolutionWindow from '@components/Window/CreateSolutionWindow';
import CreateSubtitleWindow from '@components/Window/CreateSubtitleWindow';
import CreateTemplateKeyWindow from '@components/Window/CreateTemplateKeyWindow';
import CreateTemplateWindow from '@components/Window/CreateTemplateWindow';
import CreateTimelineTemplateWindow from '@components/Window/CreateTimelineTemplateWindow';
import CreateTranslationSubtitleWindow from '@components/Window/CreateTranslationSubtitleWindow';
import CreateVideoSpreadWindow from '@components/Window/CreateVideoSpreadWindow';
import CreateVideoTemplateWindow from '@components/Window/CreateVideoTemplateWindow';
import CustomDesignWindow from '@components/Window/CustomDesignWindow';
import DateWindow from '@components/Window/DateWindow';
import DeleteTemplateKeyWindow from '@components/Window/DeleteTemplateKeyWindow';
import EditingBriefWindow from '@components/Window/EditingBriefWindow';
import EditSolutionRouteWindow from '@components/Window/EditSolutionRouteWindow';
import ErrorWindow from '@components/Window/ErrorWindow';
import EventWindow from '@components/Window/EventWindow';
import ExportPresetValuesWindow from '@components/Window/ExportPresetValuesWindow';
import ExtractThumbnailWindow from '@components/Window/ExtractThumbnailWindow';
import FinishWorkflowTaskWindow from '@components/Window/FinishWorkflowTaskWindow';
import HtmlPreviewWindow from '@components/Window/HtmlPreviewWindow';
import ImportCardsWindow from '@components/Window/ImportCardsWindow';
import ImportPresetValuesWindow from '@components/Window/ImportPresetValuesWindow';
import InjectionWindow from '@components/Window/InjectionWindow';
import InProgressVideoInformationWindow from '@components/Window/InProgressVideoInformationWindow';
import MailWindow from '@components/Window/MailWindow';
import MediasWindow from '@components/Window/MediasWindow';
import MessageWindow from '@components/Window/MessageWindow';
import OrderProblemWindow from '@components/Window/OrderProblemWindow';
import OrderWindow from '@components/Window/OrderWindow';
import PermissionRoleWindow from '@components/Window/PermissionRoleWindow';
import SelectNormalizeOptionsWindow from '@components/Window/SelectNormalizeOptionsWindow';
import SequencesWindow from '@components/Window/SequencesWindow';
import SocialMediaPlayer from '@components/Window/SocialMediaPlayer';
import StudioShortcutsWindow from '@components/Window/StudioShortcutsWindow';
import UpdateAvatarWindow from '@components/Window/UpdateAvatarWindow';
import UpdateGroupWindow from '@components/Window/UpdateGroupWindow';
import UpdateProductWindow from '@components/Window/UpdateProductWindow';
import UploadSocialMediaVideo from '@components/Window/UploadSocialMediaVideo';
import UpsertExternalVideoWindow from '@components/Window/UpsertExternalVideoWindow';
import ValidationBroadcastingStatusWindow from '@components/Window/ValidationBroadcastingStatusWindow';
import WatermarksWindow from '@components/Window/WatermarksWindow';
import WebhookWindow from '@components/Window/WebhookWindow';
import WorkflowBriefWindow from '@components/Window/WorkflowBriefWindow';
import WorkflowExitWindow from '@components/Window/WorkflowExitWindow';
import WorkflowPauseWindow from '@components/Window/WorkflowPauseWindow';

const ADD_SOLUTION = 'ADD_SOLUTION';
const ADD_COMPANY = 'ADD_COMPANY';
const ADD_SOLUTION_ROUTE = 'ADD_SOLUTION_ROUTE';
const EDIT_SOLUTION_ROUTE = 'EDIT_SOLUTION_ROUTE';
const CARDS = 'CARDS';
const COMMENT = 'COMMENT';
const COMMENT_FEELING = 'COMMENT_FEELING';
const DATE = 'DATE';
const EVENT = 'EVENT';
const CONTRACT = 'CONTRACT';
const PLAY_MEDIAS = 'PLAY_MEDIAS';
const ADD_MEDIA = 'ADD_MEDIA';
const ADD_MEDIAS = 'ADD_MEDIAS';
const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
const EXTRACT_THUMBNAIL = 'EXTRACT_THUMBNAIL';
const CONFIRM = 'CONFIRM';
const ADD_SUBTITLE = 'ADD_SUBTITLE';
const ADD_TRANSLATION_SUBTITLE = 'ADD_TRANSLATION_SUBTITLE';
const ADD_CARDS = 'ADD_CARDS';
const IMPORT_CARDS = 'IMPORT_CARDS';
const STUDIO_SHORTCUTS = 'STUDIO_SHORTCUTS';
const ADD_CHANGELOG = 'ADD_CHANGELOG';
const EXPORT_PRESET_VALUES = 'EXPORT_PRESET_VALUES';
const IMPORT_PRESET_VALUES = 'IMPORT_PRESET_VALUES';
const ADD_TEMPLATE_KEY = 'ADD_TEMPLATE_KEY';
const DELETE_TEMPLATE_KEY = 'DELETE_TEMPLATE_KEY';
const CREATE_PRESET = 'CREATE_PRESET';
const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
const CREATE_SOCIAL_MEDIA_VIDEO = 'CREATE_SOCIAL_MEDIA_VIDEO';
const CREATE_SOCIAL_MEDIA_TEMPLATE = 'CREATE_SOCIAL_MEDIA_TEMPLATE';
const WATERMARKS = 'WATERMARKS';
const CUSTOM_DESIGN_WINDOW = 'CUSTOM_DESIGN_WINDOW';
const PERMISSION_ROLE = 'PERMISSION_ROLE';
const UPLOAD_SOCIAL_MEDIA_VIDEO = 'UPLOAD_SOCIAL_MEDIA_VIDEO';
const SOCIAL_MEDIA_PLAYER = 'SOCIAL_MEDIA_PLAYER';
const EDITING_BRIEF = 'EDITING_BRIEF';
const CREATE_POLICY = 'CREATE_POLICY';
const ASSIGN_ROLE = 'ASSIGN_ROLE';
const MAIL = 'MAIL';
const NORMALIZE_OPTIONS = 'NORMALIZE_OPTIONS';
const BASIC_WINDOW = 'BASIC_WINDOW';
const HTML_PREVIEW = 'HTML_PREVIEW';
const CREATE_BROWSER_WORKER = 'CREATE_BROWSER_WORKER';
const SEQUENCES = 'SEQUENCES';
const AUDIT_TASK_OVERVIEW = 'AUDIT_TASK_OVERVIEW';
const AUDIT_TASK = 'AUDIT_TASK';
const INJECTION = 'INJECTION';
const IN_PROGRESS_VIDEO = 'IN_PROGRESS_VIDEO';
const CREATE_SPREAD_VIDEO = 'CREATE_SPREAD_VIDEO';
const CREATE_GROUP = 'CREATE_GROUP';
const UPDATE_GROUP = 'UPDATE_GROUP';
const WORKFLOW_FINISH_TASK = 'WORKFLOW_FINISH_TASK';
const WORKFLOW_BRIEF = 'WORKFLOW_BRIEF';
const WORKFLOW_PAUSE = 'WORKFLOW_PAUSE';
const ERROR = 'ERROR';
const MESSAGE = 'MESSAGE';
const WORKFLOW_EXIT = 'WORKFLOW_EXIT';
const WEBHOOK = 'WEBHOOK';
const CREATE_QRCODE_TEMPLATE = 'CREATE_QRCODE_TEMPLATE';
const ADDRESS = 'ADDRESS';
const CREATE_TIMELINE_TEMPLATE = 'CREATE_TIMELINE_TEMPLATE';
const SILENCE_SMART_CUT = 'SILENCE_SMART_CUT';
const UPDATE_AVATAR = 'UPDATE_AVATAR';
const ORDER_WINDOW = 'ORDER_WINDOW';
const ORDER_PROBLEM_WINDOW = 'ORDER_PROBLEM_WINDOW';
const UPSERT_EXTERNALVIDEO_WINDOW = 'UPSERT_EXTERNALVIDEO_WINDOW';
const VALIDATION_BROADCASTING_STATUS_WINDOW = 'VALIDATION_BROADCASTING_STATUS_WINDOW';

export const WINDOW_TYPES = {
  ADD_SOLUTION,
  ADD_COMPANY,
  ADD_SOLUTION_ROUTE,
  EDIT_SOLUTION_ROUTE,
  CARDS,
  COMMENT,
  COMMENT_FEELING,
  DATE,
  EVENT,
  CONTRACT,
  PLAY_MEDIAS,
  ADD_MEDIA,
  ADD_MEDIAS,
  UPDATE_PRODUCT,
  UPDATE_AVATAR,
  EXTRACT_THUMBNAIL,
  CONFIRM,
  ADD_SUBTITLE,
  ADD_TRANSLATION_SUBTITLE,
  ADD_CARDS,
  IMPORT_CARDS,
  STUDIO_SHORTCUTS,
  ADD_CHANGELOG,
  EXPORT_PRESET_VALUES,
  IMPORT_PRESET_VALUES,
  ADD_TEMPLATE_KEY,
  DELETE_TEMPLATE_KEY,
  CREATE_PRESET,
  CREATE_TEMPLATE,
  CREATE_SOCIAL_MEDIA_VIDEO,
  CREATE_SOCIAL_MEDIA_TEMPLATE,
  WATERMARKS,
  PERMISSION_ROLE,
  UPLOAD_SOCIAL_MEDIA_VIDEO,
  SOCIAL_MEDIA_PLAYER,
  EDITING_BRIEF,
  CREATE_POLICY,
  ASSIGN_ROLE,
  MAIL,
  NORMALIZE_OPTIONS,
  BASIC_WINDOW,
  HTML_PREVIEW,
  CREATE_BROWSER_WORKER,
  CUSTOM_DESIGN_WINDOW,
  SEQUENCES,
  AUDIT_TASK_OVERVIEW,
  AUDIT_TASK,
  INJECTION,
  IN_PROGRESS_VIDEO,
  CREATE_SPREAD_VIDEO,
  CREATE_GROUP,
  UPDATE_GROUP,
  WORKFLOW_FINISH_TASK,
  WORKFLOW_BRIEF,
  WORKFLOW_PAUSE,
  ERROR,
  MESSAGE,
  WORKFLOW_EXIT,
  WEBHOOK,
  CREATE_QRCODE_TEMPLATE,
  ADDRESS,
  CREATE_TIMELINE_TEMPLATE,
  SILENCE_SMART_CUT,
  ORDER_WINDOW,
  ORDER_PROBLEM_WINDOW,
  UPSERT_EXTERNALVIDEO_WINDOW,
  VALIDATION_BROADCASTING_STATUS_WINDOW,
};

const windows = {
  [ADD_SOLUTION]: CreateSolutionWindow,
  [ADD_COMPANY]: CreateCompanyWindow,
  [ADD_SOLUTION_ROUTE]: CreateSolutionRouteWindow,
  [EDIT_SOLUTION_ROUTE]: EditSolutionRouteWindow,
  [CARDS]: CardsWindow,
  [COMMENT]: CommentWindow,
  [COMMENT_FEELING]: CommentFeelingWindow,
  [DATE]: DateWindow,
  [EVENT]: EventWindow,
  [CONTRACT]: ContractWindow,
  [PLAY_MEDIAS]: MediasWindow,
  [ADD_MEDIA]: AddMediaWindow,
  [ADD_MEDIAS]: AddMediasWindow,
  [UPDATE_AVATAR]: UpdateAvatarWindow,
  [UPDATE_PRODUCT]: UpdateProductWindow,
  [EXTRACT_THUMBNAIL]: ExtractThumbnailWindow,
  [CONFIRM]: ConfirmationWindow,
  [ADD_SUBTITLE]: CreateSubtitleWindow,
  [ADD_TRANSLATION_SUBTITLE]: CreateTranslationSubtitleWindow,
  [ADD_CARDS]: CreateCardsWindow,
  [IMPORT_CARDS]: ImportCardsWindow,
  [STUDIO_SHORTCUTS]: StudioShortcutsWindow,
  [ADD_CHANGELOG]: AddNewChangeLog,
  [EXPORT_PRESET_VALUES]: ExportPresetValuesWindow,
  [IMPORT_PRESET_VALUES]: ImportPresetValuesWindow,
  [ADD_TEMPLATE_KEY]: CreateTemplateKeyWindow,
  [DELETE_TEMPLATE_KEY]: DeleteTemplateKeyWindow,
  [CREATE_PRESET]: CreatePresetWindow,
  [CREATE_TEMPLATE]: CreateTemplateWindow,
  [CREATE_SOCIAL_MEDIA_VIDEO]: CreateSocialMediaVideo,
  [CREATE_SOCIAL_MEDIA_TEMPLATE]: CreateVideoTemplateWindow,
  [WATERMARKS]: WatermarksWindow,
  [PERMISSION_ROLE]: PermissionRoleWindow,
  [UPLOAD_SOCIAL_MEDIA_VIDEO]: UploadSocialMediaVideo,
  [SOCIAL_MEDIA_PLAYER]: SocialMediaPlayer,
  [EDITING_BRIEF]: EditingBriefWindow,
  [CREATE_POLICY]: CreatePolicyWindow,
  [ASSIGN_ROLE]: AssignRole,
  [MAIL]: MailWindow,
  [NORMALIZE_OPTIONS]: SelectNormalizeOptionsWindow,
  [BASIC_WINDOW]: BasicWindow,
  [CUSTOM_DESIGN_WINDOW]: CustomDesignWindow,
  [HTML_PREVIEW]: HtmlPreviewWindow,
  [CREATE_BROWSER_WORKER]: CreateBrowserWorkerWindow,
  [SEQUENCES]: SequencesWindow,
  [AUDIT_TASK_OVERVIEW]: AuditTaskOverviewWindow,
  [AUDIT_TASK]: AuditTaskWindow,
  [INJECTION]: InjectionWindow,
  [IN_PROGRESS_VIDEO]: InProgressVideoInformationWindow,
  [CREATE_SPREAD_VIDEO]: CreateVideoSpreadWindow,
  [CREATE_GROUP]: CreateGroupWindow,
  [UPDATE_GROUP]: UpdateGroupWindow,
  [WORKFLOW_FINISH_TASK]: FinishWorkflowTaskWindow,
  [WORKFLOW_BRIEF]: WorkflowBriefWindow,
  [WORKFLOW_PAUSE]: WorkflowPauseWindow,
  [ERROR]: ErrorWindow,
  [MESSAGE]: MessageWindow,
  [WORKFLOW_EXIT]: WorkflowExitWindow,
  [WEBHOOK]: WebhookWindow,
  [CREATE_QRCODE_TEMPLATE]: CreateQRCodeTemplateWindow,
  [ADDRESS]: AddressWindow,
  [CREATE_TIMELINE_TEMPLATE]: CreateTimelineTemplateWindow,
  [ORDER_WINDOW]: OrderWindow,
  [ORDER_PROBLEM_WINDOW]: OrderProblemWindow,
  [UPSERT_EXTERNALVIDEO_WINDOW]: UpsertExternalVideoWindow,
  [VALIDATION_BROADCASTING_STATUS_WINDOW]: ValidationBroadcastingStatusWindow,
};

const defaultState = { isOpen: false, isLoading: false, type: null, props: null };

export const WindowContext = createContext({
  ...defaultState,
  actions: {
    open(type, props) {},
    loading() {},
    close() {},
    changeProps(props) {},
  },
});

export const useWindows = () => useContext(WindowContext);

const WindowManager = () => {
  const { isOpen, isLoading, actions, type, props, key } = useWindows();
  const CurrentWindow = windows[type];

  if (!CurrentWindow) {
    if (type) {
      console.error(`Unknown window: ${type}`);
    }
    return null;
  }
  return <CurrentWindow open={isOpen} isLoading={isLoading} onClose={actions.close} key={key} {...props} />;
};

export const WindowProvider = ({ children }) => {
  const [{ isOpen, isLoading, type, props, key }, setState] = useState(defaultState);

  const actions = useMemo(
    () => ({
      open(type, props = {}) {
        setState({
          isOpen: true,
          key: new Date(),
          type,
          props,
        });
      },
      changeProps(props = {}) {
        setState((state) => ({
          ...state,
          props: {
            ...state.props,
            ...props,
          },
        }));
      },
      close() {
        setState(defaultState);
      },
      loading() {
        setState((state) => ({ ...state, isLoading: true }));
      },
      disableLoading() {
        setState((state) => ({ ...state, isLoading: false }));
      }
    }),
    []
  );
  return (
    <WindowContext.Provider value={{ isOpen, isLoading, type, props, actions, key }}>
      <WindowManager />
      {children}
    </WindowContext.Provider>
  );
};
