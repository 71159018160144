import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import React from 'react';

export const FEATURE_FLAG_NAMES = {
  SUBTITLES_OPENAI: 'ff_subtitles_openai',
  SSE_ON_PROJECT_PAGE: 'ff_sse_on_project_page',
  MESSAGING_PROJECT: 'ff_messaging_project',
  SHOPPABLE_ADMIN_WIDGET: 'ff_shoppable_admin_widget',
  USM_DASHBOARD_SUBTITLES: 'ff_usm_dashboard_subtitles',
  SHOW_COMPANY_CONFIG: 'ff_show_company_config',
  EAN_MANDATORY: 'ff-mandatory-product-ean',
  HIDE_PROJECT_METADATA_BLOCK: 'ff_hide_project_metadata_block',
  SHOW_EXPORTS_PAGE: 'ff-export-page',
}

export const UnleashClassFlagProvider = ({ render, flagName }) => {
  const enabled = useFlag(flagName);

  return (
    <>
      {
        render({ isEnabled: enabled })
      }
    </>
  );
};

UnleashClassFlagProvider.propTypes = {
  render: PropTypes.func,
  flagName: PropTypes.string,
};
