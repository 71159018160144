import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Action from '@components/Common/Action';
import Banner from '@components/Common/Banner';
import Block from '@components/Common/Block';
import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import Flex from '@components/Common/Flex';
import Hint from '@components/Common/Hint';
import LabelAction from '@components/Common/LabelAction';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Tag from '@components/Common/Tag';
import { Form, useForm } from '@components/FormElement/Form';
import Select from '@components/FormElement/Select';
import Switch from '@components/FormElement/Switch';
import PageLoading from '@components/Layout/PageLoading';
import AdaptativeCell from '@components/TableElement/AdaptativeCell';
import Cell from '@components/TableElement/Cell';
import { Table } from '@components/TableElement/Table';
import { useWindows, WINDOW_TYPES } from '@contexts/WindowContext';
import { useTable } from '@hooks/useTable';

import style from './style.module.css';

const getHintSelect = (t) => (configuration) => {
  const missingFields = configuration.filter(({ isRequired, csvKey }) => isRequired && !csvKey);

  if (missingFields.length) {
    return {
      type: 'ERROR',
      message: t('Fields are missing: {{fields}}', {
        fields: missingFields.map((field) => ` ${field?.value}`),
      }),
    };
  }
};

const normalizedData = (configuration, data) => {
  const normalizedData = configuration.reduce(
    (acc, { value, csvKey, multi }) =>
      multi
        ? {
            ...acc,
            [value]: csvKey?.reduce((acc, key) => [...acc.concat(data[key])], []).filter((e) => e),
          }
        : {
            ...acc,
            [value]: !data[csvKey] ? undefined : data[csvKey],
          },
    {}
  );
  return normalizedData;
};

const BulkImportForm = ({
  data = [],
  headers: headersInit,
  headerValues,
  config,
  importType,
  isUpdating = false,
  onUpdateData = () => {},
  onSubmit = () => {},
}) => {
  const { t } = useTranslation();
  const { actions } = useWindows();
  const { solutionId } = useParams();
  const [
    { disabledRowIndexes, openRowIndexes, isEditable, configuration },
    setTable,
    setRow,
    updateDisabledRow,
    updateOpenRow,
    setUrl,
    setErrorLoading,
    getHint,
    getHints,
    setTableHint,
  ] = useTable(config, data);
  const [headers, setHeaders] = useState(headersInit);

  const hints = getHints();

  // ------------- ClassName --------------

  const isEditableClassName = isEditable ? '' : style.disabledEdit;
  const openRowClassName = (index) => (openRowIndexes.includes(index) ? style.openCell : '');
  const disabledRowClassName = (index) => (disabledRowIndexes.includes(index) ? style.disabledRowElement : '');

  const checkboxArchivedProducts = {
    value: 'isActivated',
    label: t('Enable archived products'),
  };
  const methods = useForm({ archivedProducts: 'isActivated' });

  // --------------- Hints ----------------

  const getHintRow = (index) => {
    let message = '';
    const [{ missingFields, invalidUrlFields, invalidExternVideoUrlFields }] = getHint(index);

    if (missingFields.length || invalidUrlFields.length || invalidExternVideoUrlFields.length) {
      if (missingFields.length) {
        message = t('Values are missing: {{message}}', { message: missingFields });
      }
      if (invalidUrlFields.length) {
        message = missingFields.length
          ? message.concat(' - ').concat(t('Invalid Url: {{message}}', { message: invalidUrlFields }))
          : message.concat(t('Invalid Url: {{message}}', { message: invalidUrlFields }));
      }
      if (invalidExternVideoUrlFields.length) {
        message = missingFields.length
          ? message.concat(' - ').concat(t('Invalid external video Url: {{message}}', { message: invalidExternVideoUrlFields }))
          : message.concat(t('Invalid external video Url: {{message}}', { message: invalidExternVideoUrlFields }));
      }

      return {
        type: 'WARNING',
        message: message,
      };
    }
  };

  // --------------- Utils ----------------

  const isDisabledSwitch = (index) => !!(disabledRowIndexes.includes(index) && hints.find((h) => h.id === index));

  const getAssociateKey = (key) => {
    const keyValue = configuration?.find((c) => c.csvKey === key);
    if (keyValue) {
      return keyValue?.value;
    }
    const multiKeyValue = configuration?.filter((c) => c.multi);

    const keyMulti = multiKeyValue?.find((value) => value.csvKey?.includes(key));
    if (keyMulti) {
      return keyMulti?.value;
    }
  };

  // --------------- Edit -----------------

  const updateData = (data, index) => {
    const normalizedValues = normalizedData(configuration, data[index]);

    if (importType === 'EXTERNAL_VIDEOS') {
      onUpdateData(normalizedValues, index);
    } else {
      actions.open(WINDOW_TYPES.UPDATE_PRODUCT, {
        product: {
          ...normalizedValues,
          quantity: normalizedValues.quantity || 1,
        },
        solutionId,
        onEdit: (values) => {
          actions.loading();
          setRow(data, values, index);
          actions.close();
        },
      });
    }
  };

  // -------------- Submit ----------------

  const confirmationImport = (callback) => {
    const messageNbImport = t('You will import {{count}} line.', { count: data.length - disabledRowIndexes.length });
    const messageNotImport = t(' {{count}} line will not be imported.', { count: disabledRowIndexes.length });

    actions.open(WINDOW_TYPES.CONFIRM, {
      action: callback,
      message: hints.length ? messageNbImport.concat(messageNotImport) : messageNbImport,
    });
  };

  const _onSubmit = (data) => {
    const filteredData = data.filter((_, index) => !disabledRowIndexes.includes(index));
    const rows = filteredData.map((row) => normalizedData(configuration, row));
    onSubmit(rows, !!methods.getValues('archivedProducts'));
  };

  // Method used by the real table header in the HTML
  const setHeaderValue = (key) => {
    const header = headers?.find((h) => h.position === +key);
    const name = headerValues.find((hv) => hv.value.toLowerCase() === header.name)?.configurationName;
    const config = configuration.find((c) => c.label === name);
    return config?.value;
  };

  const setValues = (key, value, data) => {
    setHeaders([...headers.filter((h) => h.position !== +key), ...[{ name: value, position: +key }]]);
    setTable(key, value, data);
  };

  // Set header on tab for earch headers return from the file
  // Must be separate from the method setHeaderValue because of the setTable create infinite loop
  useEffect(() => {
    headers?.forEach((h) => {
      const name = headerValues.find((hv) => hv.value.toLowerCase() === h.name)?.configurationName;
      const config = configuration.find((c) => c.label === name);
      if (config?.value) {
        setTable(h.position.toString(), config.value, data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // We need to check all hints eachtime the data is refresh to show/hide hints on the BO
  useEffect(() => {
    headers?.forEach((h) => {
      const name = headerValues.find((hv) => hv.value.toLowerCase() === h.name)?.configurationName;
      const config = configuration.find((c) => c.label === name);
      if (config?.value) {
        setTableHint(h.position.toString(), config.value, data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {hints.length !== 0 && (
        <Block className={style.warningContainer}>
          <p className={style.warningTitle}>{t('Some errors have been detected:')}</p>
          <SkeepersIcon type="WARNING" className={style.warningIcon} />
          {hints.map((h) => {
            return (
              <Tag
                key={h.id}
                value={t('L{{id}} - {{message}}', {
                  id: h.id + 1,
                  message: h.fields,
                })}
                action={() => (window.location = `#${h.id - 2 < 0 ? 'tab' : h.id - 2}`)}
                color="var(--color-warning)"
                className={style.warningTag}
              />
            );
          })}
          {hints.some((h) => h.isUpdatable) && (
            <ButtonContainer>
              <Button label={t('Automatically correct invalid url')} action={() => setUrl(data)} />
            </ButtonContainer>
          )}
        </Block>
      )}
      {!isUpdating ? (
        <Block id="tab" className={style.blockTab}>
          <Table overflow>
            <Table.Header>
              <Table.HeaderElement title={t('ID')} className={style.idRow} />
              {Object.keys(data[0]).map((key) => (
                <Table.HeaderElement key={key} className={style.headerCol}>
                  <Select
                    value={setHeaderValue(key)}
                    isClearable={true}
                    options={configuration}
                    onEdit={(value) => setValues(key, value, data)}
                    isOptionDisabled={(option) => option.isDisabled}
                    className={style.selectElement}
                  />
                </Table.HeaderElement>
              ))}
              <Table.HeaderElement title={t('Import')} className={style.importRow} />
            </Table.Header>
            {data.map((d, index) => (
              <Table.Row key={index} id={index} className={style.row}>
                <Table.RowElement className={`${disabledRowClassName(index)} ${style.idRow}`}>
                  <Cell>
                    <p>{index + 1}</p>
                  </Cell>
                </Table.RowElement>
                {Object.keys(d).map((key) => (
                  <Table.RowElement key={key} className={`${disabledRowClassName(index)} ${style.rowElement}`}>
                    <AdaptativeCell
                      value={d[key]}
                      type={getAssociateKey(key)}
                      className={`${openRowClassName(index)}`}
                      onError={() =>
                        setErrorLoading(
                          index,
                          configuration.find((c) => c.csvKey === key)
                        )
                      }
                    />
                  </Table.RowElement>
                ))}
                <Table.RowElement className={style.importRow}>
                  <Cell>
                    {importType === 'PRODUCTS' && (
                      <Switch
                        className={style.switch}
                        disabled={isDisabledSwitch(index)}
                        controledValue={!disabledRowIndexes.includes(index)}
                        onEdit={updateDisabledRow(index)}
                      />
                    )}
                    <LabelAction text={t('Edit')} icon="EDIT" action={isEditable ? () => updateData(data, index) : () => {}} className={isEditableClassName} />
                    {importType === 'PRODUCTS' &&
                      (openRowIndexes.includes(index) ? (
                        <LabelAction text={t('Hidden')} icon="EYESLASH" action={() => updateOpenRow(index)(true)} />
                      ) : (
                        <LabelAction text={t('See All')} icon="EYE" action={() => updateOpenRow(index)(false)} />
                      ))}
                  </Cell>
                </Table.RowElement>
                {disabledRowIndexes.includes(index) && <Hint className={style.hintRow} {...getHintRow(index)} />}
              </Table.Row>
            ))}
          </Table>
        </Block>
      ) : (
        <PageLoading applyRelativePositionStyle={true} />
      )}
      <Banner onClick={() => (window.location = `#top`)}>
        {!!hints.length && (
          <Action target="_self" action="#top">
            <Hint
              type="WARNING"
              message={t('We have detected {{count}} incomplete line. Take a Look!', {
                count: hints.length,
              })}
            />
          </Action>
        )}
        {getHintSelect(t)(configuration) && <Hint {...getHintSelect(t)(configuration)} />}
        <Block padding="SMALL">
          <Form methods={methods} onSubmit={() => confirmationImport(() => _onSubmit(data))} skipDirtyCheck={true}>
            <Flex alignItems="STRETCH" margin="NONE" gap="0.5rem">
              {importType === 'PRODUCTS' && (
                <Form.Label name="archivedProducts" className="activateArchivedProducts">
                  <Form.CheckboxGroup name="archivedProducts" defaultValue="isActivated" options={[checkboxArchivedProducts]} />
                </Form.Label>
              )}
              <Form.SubmitButton
                label={t('Import')}
                disabled={configuration.find((element) => element.isRequired && !element.csvKey) || hints.length === data.length ? true : false}
                loading={isUpdating}
              />
            </Flex>
          </Form>
        </Block>
      </Banner>
    </>
  );
};

export default BulkImportForm;

BulkImportForm.propsTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.object,
  headerValues: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      isRequired: PropTypes.bool,
      multi: PropTypes.bool,
    })
  ),
  importType: PropTypes.string,
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
  onUpdateData: PropTypes.func,
};
