import { SkpAlert, SkpButton, SkpFlex, SkpMultiselect, SkpSelect, SkpText } from '@skeepers/skeepers-ui-react';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { findLangBySubtitleCode, findSubtitleCodeByLang, getLanguages } from '@app/translations';
import Hint from '@components/Common/Hint';
import Label from '@components/FormElement/Label';

import styles from './style.module.css';

const CreateTranslationSubtitleForm = ({ subtitles = [], isLoading = false, onSubmit = () => {}, error }) => {
  const { t } = useTranslation();

  const languages = useMemo(() => getLanguages(t), [t]);
  const existingLang = useMemo(() => subtitles.map(({ language }) => findLangBySubtitleCode(languages, language)), [languages, subtitles]);
  const languagesOpen = useMemo(() => {
    const existingLangCountry = existingLang.map(({ country }) => country);
    return Object.keys(languages).filter((key) => !existingLangCountry.includes(key));
  }, [existingLang, languages]);

  const [fromValue, setFromValue] = useState(existingLang.length === 1 ? existingLang[0].country : undefined);
  const [intoValue, setIntoValue] = useState(languagesOpen.length === 1 ? languagesOpen : []);

  const submit = useMemo(() =>
    () => {
      if (fromValue && intoValue) {
        onSubmit(
          fromValue,
          intoValue.map((code) => findSubtitleCodeByLang(code)),
        );
      }
    },
    [fromValue, intoValue, onSubmit],
  );

  return (
    <SkpFlex flexDirection='column' alignItems='stretch' gap={4}>
      <Label title={t('Translate from')} name="from">
        <SkpSelect
          name="from"
          placeholder={t('Translate from')}
          size="medium"
          options={existingLang.map(({name, country}) => ({
            value: country,
            text: name,
          }))}
          value={fromValue}
          onSkpSelectChange={({detail}) => {
            if (detail) {
              setFromValue(detail);
            }
          }}
          disabled={existingLang.length === 1}
        ></SkpSelect>
      </Label>
      <Label title={t('Translate into')} name="into">
        <SkpMultiselect
          name="into"
          placeholder={t('Translate into')}
          size="medium"
          options={languagesOpen.map((lang) => ({
            value: lang,
            text: languages[lang].name,
            type: 'checkbox',
          }))}
          values={intoValue}
          onSkpSelectChange={({detail}) => setIntoValue(detail)}
          disabled={isLoading}
        ></SkpMultiselect>
      </Label>
      <div className={styles.center}>
        {isLoading &&
          <SkpText size='small' text={t('Subtitles are being generated, please wait...')}/>
        }
      </div>
      <div>
        {intoValue.length > 5 && <SkpAlert size="small" type="warning" text={t('You can not choose more than 5 languages at the same time')} />}
        {error && <Hint type="ERROR" message={error} />}
      </div>
      <SkpButton
        size="medium"
        type="primary"
        disabled={isLoading || !intoValue.length || intoValue.length > 5}
        loading={isLoading}
        onSkpClick={submit}
        text={t('Translate')}
        className={styles.submitButton}
      />
    </SkpFlex>
  );
};

export default CreateTranslationSubtitleForm;

CreateTranslationSubtitleForm.propTypes = {
  subtitles: PropTypes.array,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
}
