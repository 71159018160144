import { useQuery, useMutation, gql } from '@apollo/client';

import { PAGINATION_DEFAULT_RELAY } from '@app/config';
import { capitalizeString } from '@app/utils/strings';
import CREATE_PRODUCTS from '@graphql/products/createProducts.graphql';
import DELETE_PRODUCT from '@graphql/products/deleteProduct.graphql';
import GET_BRANDS from '@graphql/products/getBrands.graphql';
import GET_PRODUCT from '@graphql/products/getProduct.graphql';
import GET_PRODUCT_CATEGORIES from '@graphql/products/getProductCategories.graphql';
import IMPORT_PRODUCTS from '@graphql/products/importProducts.graphql';
import PRODUCTS from '@graphql/products/products.graphql';
import SET_PRODUCT_ARCHIVED_STATUS from '@graphql/products/setProductArchivedStatus.graphql';
import SET_PRODUCTS_ARCHIVED_STATUS from '@graphql/products/setProductsArchivedStatus.graphql';
import UPDATE_PRODUCT from '@graphql/products/updateProduct.graphql';

export const productStatus = ['ALL', 'AVAILABLE', 'ARCHIVED'];

export const setFilters = (fullText, archived, inStock) => {
  return {
    ...(fullText ? { fullText } : {}),
    ...(archived && archived !== 'ALL' ? { archived: archived === 'ARCHIVED' } : {}),
    ...(inStock && inStock !== 'ALL' ? { inStock: inStock === 'IN_STOCK' } : {}),
  };
};

export const useGetProducts = ({ first, after, last, before, solutionId, fullText = '', archived = 'ALL', inStock = 'ALL', skip = false }) => {
  const { data, loading, error, fetchMore, refetch } = useQuery(PRODUCTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first,
      after,
      last,
      before,
      solutionId,
      filter: setFilters(fullText, archived, inStock),
    },
    skip,
  });
  return {
    data: data?.getSolution?.products,
    stats: data?.getSolution?.productStats,
    loading,
    error,
    fetchMore,
    refetch,
  };
};

export const useGetProduct = (productId, { skip = false } = {}) => {
  const { data, loading, error } = useQuery(GET_PRODUCT, {
    variables: { productId },
    fetchPolicy: 'cache-and-network',
    skip: !productId || skip,
  });
  return { product: data?.node, loading, error };
};

export const useUpdateProduct = (productId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [updateFunction, { data, error, loading }] = useMutation(UPDATE_PRODUCT, {
    onCompleted,
    onError,
  });

  const updateProduct = (product) => {
    return updateFunction({ variables: { productId, product } });
  };

  return [updateProduct, { data: data?.updateProduct, isUpdating: loading, error }];
};

export const useCreateProducts = (solutionId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [importFunction, { data, error, loading }] = useMutation(CREATE_PRODUCTS, {
    onCompleted,
    onError,
  });

  const createProducts = (products) => {
    const normalizedProducts = products.map((product) => ({
      ...product,
      quantity: Number(product.quantity) || 1,
      tags: product.tags || [],
    }));
    return importFunction({ variables: { solutionId, products: normalizedProducts } });
  };

  return [createProducts, { data: data?.createProducts, isUpdating: loading, error }];
};

export const useImportProducts = (solutionId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [importFunction, { data, error, loading }] = useMutation(IMPORT_PRODUCTS, {
    onCompleted,
    onError,
  });

  const importProducts = (products, enableArchivedProduct) => {
    const normalizedProducts = products.map((product) => ({
      ...product,
      quantity: Number(product.quantity) || 1,
      tags: product.tags || [],
      categories: product.categories || [],
    }));
    return importFunction({ variables: { solutionId, products: normalizedProducts, enableArchivedProduct } });
  };

  return [importProducts, { data: data?.importProducts, isUpdating: loading, error }];
};

export const useDeleteProduct = (productId, solutionId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [deleteFuntion, { data, error, loading }] = useMutation(DELETE_PRODUCT, {
    onCompleted,
    onError,
    refetchQueries: [{ query: PRODUCTS, variables: { solutionId, first: PAGINATION_DEFAULT_RELAY } }],
  });

  const deleteProduct = () => {
    return deleteFuntion({ variables: { id: productId } });
  };

  return [deleteProduct, { data: data?.deleteProduct, isUpdating: loading, error }];
};

export const useSetArchivedProductStatus = () => {
  const [updateFunction, { data, error, loading }] = useMutation(SET_PRODUCT_ARCHIVED_STATUS, {
    onError: console.error,
  });

  const updateArchivedStatus = (productId, archived) => {
    return updateFunction({ variables: { archived, productId } });
  };

  return [updateArchivedStatus, { data: data?.setProductArchived, isUpdating: loading, error }];
};

export const useSetArchivedProductsStatus = (solutionId) => {
  const [updateFunction, { data, error, loading }] = useMutation(SET_PRODUCTS_ARCHIVED_STATUS, {
    onError: console.error,
    refetchQueries: [PRODUCTS],
    awaitRefetchQueries: true,
  });

  const updateArchivedStatus = (archived) => updateFunction({ variables: { solutionId, archived } });

  return [updateArchivedStatus, { data: data?.setProductsArchived, isUpdating: loading, error }];
};

export const useGetProductCategories = (solutionId) => {
  const { data, loading, error } = useQuery(GET_PRODUCT_CATEGORIES, { variables: { solutionId } });
  return { categories: data?.getProductCategoriesBySolution.map((c) => ({ label: c.name, value: c.name })), loading, error };
};

export const useLoadProduct = ({ id, fields = '' }, { skip = false } = {}) => {
  const query = gql`
      query loadProduct($id: ID!) {
          node(id: $id) {
              ... on PlatformProduct {
                  id
                  ${fields}
              }
          }
      }
  `;

  const { data, loading, error } = useQuery(query, {
    variables: { id },
    skip: !id || skip,
  });

  return { product: data?.node, loading, error };
};

export const useGetBrands = (solutionId) => {
  const { data, loading, error } = useQuery(GET_BRANDS, {
    variables: { solutionId },
    fetchPolicy: 'cache-and-network'
  });


  return {
    brands: data?.getBrands?.map(brand => ({
      label: capitalizeString(brand),
      value: brand
    })) || [],
    loading,
    error
  };
};