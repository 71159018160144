import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { socialMediaFitTranslation } from '@app/translations';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const NormalizeOptionsForm = ({ isUpdating = false, onSubmit = () => {}, input = {} }) => {
  const { t } = useTranslation();
  const methods = useForm({ autoCrop: input.autoCrop });
  const autoCrop = methods.watch('autoCrop');

  return (
    <Form skipDirtyCheck isUpdating={isUpdating} onSubmit={(values) => onSubmit({ ...values, rotateDelta: Number(values.rotateDelta) })} methods={methods}>
      <Form.Label title={t('Rotation')} name="rotateDelta" info={t('Rotate the video around the center based on a degree number')}>
        <Form.Select
          name="rotateDelta"
          defaultValue={String(input.rotateDelta) || '0'}
          options={[
            { value: '0', label: t('Disabled') },
            { value: '90', label: t('90° to the right') },
            { value: '270', label: t('90° to the left') },
            { value: '180', label: t('180°') },
          ]}
        />
      </Form.Label>
      <Form.Label title={t('Fit background')} name="fitNormalize" info={t('Choose the background on the side if the ratio of the video is not the expected one')}>
        <Form.Select
          name="fitNormalize"
          defaultValue={input.fitNormalize || 'LIGHT'}
          options={[
            { value: 'LIGHT', label: socialMediaFitTranslation.LIGHT(t) },
            { value: 'FULL', label: socialMediaFitTranslation.FULL(t) },
            { value: 'BLACK', label: socialMediaFitTranslation.BLACK(t) },
            { value: 'WHITE', label: socialMediaFitTranslation.WHITE(t) },
          ]}
        />
      </Form.Label>
      <Form.Label title={t('Reverse video (mirror)')} name="reverse" info={t('Reverse the video effect like a mirror either vertically or horizontally. To reverse phone recording use "Horizontal" value.')}>
        <Form.Select
          name="reverse"
          defaultValue={input.reverse || null}
          options={[
            { value: null, label: 'None' },
            { value: 'VERTICAL', label: t('Vertical') },
            { value: 'HORIZONTAL', label: t('Horizontal') },
          ]}
        />
      </Form.Label>
      <Form.Label title={t('Normalize audio track')} name="audioNormalize" info={t('Clean the audio to transform it in a default audio format')}>
        <Form.Switch name="audioNormalize" defaultValue={input.audioNormalize} />
      </Form.Label>
      <Form.Label title={t('Stabilize the video')} name="videoStabilization" info={t('Try to stabilize the video if it is too shaky')}>
        <Form.Switch name="videoStabilization" defaultValue={input.videoStabilization} />
      </Form.Label>
      <Form.Label title={t('Auto detect and crop black borders')} name="autoCrop" info={t('Try to auto detect black borders on the side and remove them')}>
        <Form.Switch name="autoCrop" defaultValue={input.autoCrop} />
      </Form.Label>
      {!autoCrop && input.defaultFrame && (
        <Form.Label title={t('Manual crop')} name="manualCrop">
          <Form.ManualCrop
            name="manualCrop"
            background={input.defaultFrame}
            defaultValue={{
              top: input.manualCrop?.top || 0,
              right: input.manualCrop?.right || 0,
              bottom: input.manualCrop?.bottom || 0,
              left: input.manualCrop?.left || 0,
            }}
          />
        </Form.Label>
      )}
      <ButtonContainer position="CENTER">
        <Form.SubmitButton label={t('Generate')} isUpdating={isUpdating} />
      </ButtonContainer>
    </Form>
  );
};

export default NormalizeOptionsForm;

NormalizeOptionsForm.propTypes = {
  values: PropTypes.object,
  onSubmit: PropTypes.func,
  isUpdating: PropTypes.bool,
};
