/**
 * A list of ISO 3166-1 country representations
 */
export const countries = {
  BE: 'BE',
  ES: 'ES',
  DK: 'DK',
  DE: 'DE',
  FI: 'FI',
  FR: 'FR',
  IT: 'IT',
  NL: 'NL',
  NO: 'NO',
  PT: 'PT',
  SE: 'SE',
  US: 'US',
  PL: 'PL',
  JP: 'JP',
  AE: 'AE',
  EN: 'EN',
  CN: 'CN',
  KO: 'KO',
  RU: 'RU',
  CY: 'CY',
};

/**
 * A list of ISO 639 langauge codes
 */
export const countryToLanguages = {
  FR: ['fr'],
  DK: ['da'],
  DE: ['de'],
  ES: ['es', 'ca', 'eu', 'gl', 'an'],
  NL: ['nl'],
  FI: ['fi'],
  BE: ['nl-BE'],
  IT: ['it'],
  NO: ['no', 'nb', 'nn'],
  PT: ['pt'],
  SE: ['sv'],
  PL: ['pl'],
  JP: ['ja'],
  AE: ['ar'],
  CN: ['zh'],
  KO: ['ko'],
  RU: ['ru'],
  CY: ['cy'],
  US: ['en'],
};

export const languageToCountry = Object.keys(countryToLanguages).reduce((acc, country) => {
  countryToLanguages[country].forEach((lang) => {
    acc[lang] = country;
  });
  return acc;
}, {});

/**
 * A translated list of ISO 639-1 language representations and their associated country
 */
export const getLanguages = (t) => ({
  FR: {
    name: t('French'),
    country: countries.FR,
  },
  US: {
    name: t('English'),
    country: countries.US,
  },
  DK: {
    name: t('Danish'),
    country: countries.DK,
  },
  DE: {
    name: t('German'),
    country: countries.DE,
  },
  ES: {
    name: t('Spanish'),
    country: countries.ES,
  },
  FI: {
    name: t('Finnish'),
    country: countries.FI,
  },
  NL: {
    name: t('Dutch'),
    country: countries.NL,
  },
  BE: {
    name: t('Dutch (Belgium)'),
    country: countries.BE,
  },
  IT: {
    name: t('Italian'),
    country: countries.IT,
  },
  NO: {
    name: t('Norwegian'),
    country: countries.NO,
  },
  PT: {
    name: t('Portuguese'),
    country: countries.PT,
  },
  SE: {
    name: t('Swedish'),
    country: countries.SE,
  },
  PL: {
    name: t('Polish'),
    country: countries.PL,
  },
  JP: {
    name: t('Japanese'),
    country: countries.JP,
  },
  AE: {
    name: t('Arab'),
    country: countries.AE,
  },
  CN: {
    name: t('Chinese'),
    country: countries.CN,
  },
  KO: {
    name: t('Korean'),
    country: countries.KO,
  },
  RU: {
    name: t('Russian'),
    country: countries.RU,
  },
  CY: {
    name: t('Welsh'),
    country: countries.CY,
  },
});

export const findLangBySubtitleCode = (languages, subtitleCode) => {
  if (!subtitleCode || subtitleCode === '') {
    return null;
  }

  if (subtitleCode === 'unknown') {
    return { name: 'unknown language', country: subtitleCode.toUpperCase() }
  }

  const countryFound = Object.keys(countryToLanguages).find((key) => countryToLanguages[key].includes(subtitleCode));
  if (!countryFound) {
    return null;
  }

  return languages[countryFound];
}

export const findSubtitleCodeByLang = (lang) => {
  if (!lang || lang === '') {
    return null;
  }

  return countryToLanguages[lang][0];
}

export const getProjectStepTranslation = (t) => ({
  ALL: t('All'),
  VALIDATED: t('Validated'),
  RUSHES: t('Rushes'),
  EDITING: t('Editing'),
  EDITING_REPORTED: t('Editing reported'),
  META: t('Meta'),
  REVIEW: t('Review'),
  PUBLICATION: t('Publication'),
  REFUSED: t('Refused'),
  DELETED: t('Deleted'),
  ABANDONNED: t('Abandonned'),
  PENDING: t('Rushes'),
  PENDING_TEAM_VALIDATION: t('Review'),
  PENDING_METADATA: t('Meta'),
  PENDING_CLIENT_VALIDATION: t('Waiting client validation'),
  PENDING_RUSHES: t('Waiting new rushes'),
  PENDING_TRIAGE: t('Video rejected by client'),
});

export const getProjectStatusTranslation = (t) => ({
  ALL: t('All'),
  VALIDATED: t('Validated'),
  REFUSED_RUSHES: t('Definitively rejected'),
  PENDING_RUSHES: t('Rushes to be validated'),
  PENDING: t('Pending rushes validation'),
  PENDING_EDITING: t('Video to be edited'),
  PENDING_METADATA: t('Metadata to be defined'),
  PENDING_TEAM_VALIDATION: t('Final video to be reviewed'),
  PENDING_CLIENT_VALIDATION: t('To moderate'),
  PENDING_TRIAGE: t('Rejected by the brand'),
  DELETED: t('Deleted'),
  REFUSED: t('Rejected by the brand'),
  ABANDONNED: t('Abandonned'),
  PUBLISHED: t('Published'),
  EDITING: t('Editing'),
});

export const getProjectFollowUpStatusTranslation = (t) => ({
  VALIDATED: t('Finished'),
  REFUSED_RUSHES: t('Definitively rejected'),
  PENDING_RUSHES: t('Waiting new rushes'),
  PENDING: t('Rushes'),
  PENDING_EDITING: t('Editing'),
  PENDING_METADATA: t('Metadata'),
  PENDING_TEAM_VALIDATION: t('Pending team validation'),
  PENDING_CLIENT_VALIDATION: t('Pending client validation'),
  PENDING_TRIAGE: t('Rejected by the brand'),
  REFUSED: t('Rejected by the brand'),
  UNDER_REVIEW: t('Under review'),
  ABANDONNED: t('Abandonned'),
});

export const getClientProjectStatusTranslation = (t) => ({
  ALL: t('All'),
  VALIDATED: t('Validated'),
  EDITING: t('Under review'),
  UNDER_REVIEW: t('Under review'),
  VALIDATION: t('Pending validation'),
  PENDING_CLIENT_VALIDATION: t('To moderate'),
});

export const getProductStatusTranslation = (t) => ({
  ALL: t('All'),
  AVAILABLE: t('Visible'),
  ARCHIVED: t('Archived'),
});

export const solutionShortTranslations = {
  PLATFORM_CHALLENGE: (t) => t('Challenge'),
  PLATFORM_BOOK: (t) => t('Book'),
  PLATFORM_BASIC: (t) => t('Basic'),
  PLATFORM_PRODUCT: (t) => t('Product'),
  CASTING: (t) => t('Casting'),
  LANGUAGE: (t) => t('Language'),
  VSG: (t) => t('VSG'),
  PLAYER: (t) => t('Player'),
  TRACKING: (t) => t('Tracking'),
  SMART_UPLOADER: (t) => t('Smart Uploader'),
};

export const solutionTranslations = {
  PLATFORM_TYPE: (t) => t('Platform type'),
  PLATFORM_CHALLENGE: (t) => t('Challenge - Custom brief by challenge'),
  PLATFORM_BOOK: (t) => t('Book - Ambassador orders a product'),
  PLATFORM_BASIC: (t) => t('Basic - The content creator owns the product - Unique brief'),
  PLATFORM_PRODUCT: (t) => t('Product - Ambassador owns the product - Product category'),
  CASTING: (t) => t('Casting'),
  LANGUAGE: (t) => t('Language'),
  VSG: (t) => t('VSG'),
  PLAYER: (t) => t('Player'),
  TRACKING: (t) => t('Tracking'),
  SMART_UPLOADER: (t) => t('Smart Uploader'),
};

export const getOrderStatusTag = (status, t) => {
  const statusConfig = {
    REFUSED: { text: t('Refused'), color: 'var(--tag-red)' },
    PENDING: { text: t('Preparation'), color: 'var(--skp-color-neutral-400)' },
    SENT: { text: t('Sent'), color: 'var(--tag-green)' },
    TO_BE_SENT: { text: t('To be sent'), color: 'var(--skp-color-neutral-400)' },
    DELIVERED: { text: t('Delivered'), color: 'var(--tag-green)' },
    CANCELLED: { text: t('Cancelled'), color: 'var(--tag-red)' },
  };

  return statusConfig[status] || statusConfig.PENDING;
};

export const getOrderStatusTranslation = (t) => ({
  PENDING: t('Preparation'),
  PENDING_CHOICE: t('Pending choice'),
  DELIVERED: t('Delivered'),
  REFUSED: t('Refused'),
  SENT: t('Sent'),
  CANCELLED: t('Cancelled'),
  TO_BE_SENT: t('To be sent'),
});

export const getOrderFilterTranslation = (t) => ({
  PENDING_RUSHES: t('Pending rushes'),
  WITH_PROBLEM: t('Problem'),
});

export const workflowNames = {
  APPLICATION_VALIDATION: (t) => t('Applications awaiting approval'),
  PROJECT_RUSHES_VALIDATION: (t) => t('Videos rushes awaiting approval'),
  PROJECT_EDITING: (t) => t('Videos waiting to be edited'),
  PROJECT_EDITING_VALIDATION: (t) => t('Videos awaiting editing approval'),
  PROJECT_META_EDITING: (t) => t('Videos metadata awaiting filling'),
  PROJECT_TEAM_VALIDATION: (t) => t('Videos awaiting team approval'),
};

export const getApplicationStatusTranslation = (t, isClient = false) => ({
  ALL: t('All'),
  CONFIRMED: t('Validated'),
  MAYBE: t('Maybe'),
  PENDING: t('Pending'),
  REFUSED: t('Refused'),
  PENDING_CLIENT: isClient ? t('To moderate') : t('Pending client validation'),
});

export const getApplicationProgressTranslation = (t) => ({
  ALL: t('All'),
  UPLOAD: t('Fully completed'),
  TYPEFORM: t('Completed form'),
  NONE: t('Empty'),
});

export const getApplicationProgressFilterTranslation = (t) => ({
  UPLOAD: t('Fully completed applications'),
  TYPEFORM: t('Applications with completed form'),
  NONE: t('Empty applications'),
});

export const getApplicationFeelingTranslation = (t) => ({
  ALL: t('All'),
  UNSET: t('Unset'),
  GOOD: t('Good'),
  BAD: t('Bad'),
});

export const companyContractPlateformTypeTranslation = {
  UPANDCAST: (t) => t('Up&Cast'),
  VCP: (t) => t('VCP'),
  UNKNOWN: (t) => t('Unknown'),
};

export const companyContractGoalPeriodTranslation = {
  WEEK: (t) => t('Week'),
  MONTH: (t) => t('Month'),
  YEAR: (t) => t('Year'),
  CONTRACT: (t) => t('Contract'),
};

export const companyContractContractTypeTranslation = {
  SAAS: (t) => t('SAAS'),
  PACKAGE: (t) => t('Package'),
  UNKNOWN: (t) => t('Unknown'),
};

export const socialMediaFitTranslation = {
  LIGHT: (t) => t('Static blur (Fast)'),
  FULL: (t) => t('Dynamic blur (Slow)'),
  CUSTOM_DESIGN: (t) => t('Custom design'),
  BLACK: (t) => t('Black background'),
  WHITE: (t) => t('White background'),
  CROP: (t) => t('Crop'),
};

export const getInjectionStatusTranslation = (t) => ({
  ENABLED: t('Enabled'),
  DEBUG: t('Debug'),
  DISABLED: t('Disabled'),
});

//TODO need to be moved and optimised
export const getLogEventConfig = (eventType, t) => {
  const eventConfig = {
    PROJECT_CREATION: {
      title: t('Project created'),
      iconType: 'SPARKLES',
      color: 'var(--tag-yellow)',
    },
    PROJECT_INPUT_ADD: {
      title: t('New input uploaded'),
      iconType: 'UPLOAD',
      color: 'var(--tag-yellow)',
    },
    PROJECT_INPUTS_VALIDATE: {
      title: t('Rushes validated'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-green)',
    },
    PROJECT_INPUTS_REFUSE: {
      title: t('Rushes rejected'),
      iconType: 'EXCLAMATION_SQUARE',
      color: 'var(--tag-red)',
    },
    PROJECT_INPUTS_ASK_MORE: {
      title: t('Additional rushes requested'),
      iconType: 'EMAIL',
      color: 'var(--tag-blue)',
    },
    PROJECT_OUTPUT_ADD: {
      title: t('Final video manually uploaded'),
      iconType: 'UPLOAD',
      color: 'var(--tag-yellow)',
    },
    PROJECT_STUDIO_SAVE: {
      title: t('Video saved in the studio'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    PROJECT_OUTPUT_STUDIO_EXPORT: {
      title: t('Video exported'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    PROJECT_OUTPUT_CONFIRM: {
      title: t('Video finalized'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-green)',
    },
    PROJECT_FINAL_OUTPUT_VALIDATE: {
      title: t('Video editing validated'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-green)',
    },
    PROJECT_FINAL_OUTPUT_REFUSE: {
      title: t('Review of video editing requested'),
      iconType: 'EXCLAMATION_SQUARE',
      color: 'var(--tag-red)',
    },
    PROJECT_METADATA_EDITION_GENERAL: {
      title: t('Video settings edited'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    PROJECT_METADATA_EDITION_THUMBNAIL: {
      title: t('Thumbnail edited'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    PROJECT_METADATA_EDITION_CARDS: {
      title: t('Cards edited'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    PROJECT_METADATA_EDITION_VALIDATE: {
      title: t('Video metadata finalized'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-green)',
    },
    PROJECT_TEAM_REVIEW_VALIDATE: {
      title: t('Validated by Skeepers'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-green)',
    },
    PROJECT_TEAM_REVIEW_OUTPUT_REFUSE: {
      title: t('Review of video editing requested'),
      iconType: 'EXCLAMATION_SQUARE',
      color: 'var(--tag-red)',
    },
    PROJECT_TEAM_REVIEW_METADATA_REFUSE: {
      title: t('Review of video metadata requested'),
      iconType: 'EXCLAMATION_SQUARE',
      color: 'var(--tag-red)',
    },
    PROJECT_VALIDATE: {
      title: t('Validated'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-green)',
    },
    PROJECT_CLIENT_REVIEW_REFUSE: {
      title: t('Refused by the client'),
      iconType: 'EXCLAMATION_SQUARE',
      color: 'var(--tag-red)',
    },
    PROJECT_DISTRIBUTION_ADD: {
      title: t('Broadcasting channel added'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    PROJECT_DISTRIBUTION_REMOVE: {
      title: t('Broadcasting channel removed'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    PROJECT_PRODUCT_REFERENCE_ADD: {
      title: t('Product reference added'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    PROJECT_PRODUCT_REFERENCE_REMOVE: {
      title: t('Product reference removed'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    PROJECT_COMMENT: {
      title: t('New comment'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    PROJECT_ABANDON: {
      title: t('Project abandonned'),
      iconType: 'EXCLAMATION_SQUARE',
      color: 'var(--tag-red)',
    },
    PROJECT_UNVALIDATE: {
      title: t('Project unvalidated'),
      iconType: 'EXCLAMATION_SQUARE',
      color: 'var(--tag-red)',
    },
    PROJECT_DUPLICATE: {
      title: t('Project duplicated'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-yellow)',
    },
    PROJECT_MERGED: {
      title: t('Project merged'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-yellow)',
    },
    PROJECT_CREATED_AS_DUPLICATE: {
      title: t('Project created as duplicate'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-yellow)',
    },
    PROJECT_TASK_SUBTITLE_DONE: {
      title: t('Subtitle task finished'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-red)',
    },
    PROJECT_TASK_SUBTITLE_ISSUE: {
      title: t('Subtitle task issue'),
      iconType: 'EXCLAMATION_SQUARE',
      color: 'var(--tag-red)',
    },
    PROJECT_TASK_EDITING_DONE: {
      title: t('Editing task finished'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-green)',
    },
    PROJECT_TASK_EDITING_ISSUE: {
      title: t('Editing task issue'),
      iconType: 'EXCLAMATION_SQUARE',
      color: 'var(--tag-red)',
    },
    PROJECT_TASK_REVIEW_DONE: {
      title: t('Review task finished'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-green)',
    },
    PROJECT_TASK_REVIEW_ISSUE: {
      title: t('Review task issue'),
      iconType: 'EXCLAMATION_SQUARE',
      color: 'var(--tag-red)',
    },
    ORDER_STATUS_UPDATE_TO_BE_SENT: {
      title: t('StatusToBeSent'),
      iconType: 'TRUCK',
      color: 'var(--tag-yellow)',
    },
    ORDER_STATUS_UPDATE_PENDING: {
      title: t('StatusPending'),
      iconType: 'TRUCK',
      color: 'var(--tag-yellow)',
    },
    ORDER_STATUS_UPDATE_SENT: {
      title: t('StatusSent'),
      iconType: 'PAPER_PLANE',
      color: 'var(--tag-green)',
    },
    ORDER_STATUS_UPDATE_DELIVERED: {
      title: t('StatusDelivered'),
      iconType: 'GENERAL',
      color: 'var(--tag-green)',
    },
    ORDER_STATUS_UPDATE_REFUSED: {
      title: t('StatusRefused'),
      iconType: 'CLOSE',
      color: 'var(--tag-red)',
    },
    ORDER_STATUS_UPDATE_CANCELLED: {
      title: t('StatusCancelled'),
      iconType: 'CLOSE',
      color: 'var(--tag-red)',
    },
    ORDER_COMMENT: {
      title: t('New comment'),
      iconType: 'EDIT',
      color: 'var(--tag-yellow)',
    },
    ORDER_PROBLEM_ADDED: {
      title: t('OrderProblemAdded'),
      iconType: 'GENERAL',
      color: 'var(--tag-red)',
    },
    ORDER_PROBLEM_RESOLVED: {
      title: t('OrderProblemResolved'),
      iconType: 'GENERAL',
      color: 'var(--tag-green)',
    },
    ORDER_PROBLEM_UPDATED: {
      title: t('OrderProblemUpdated'),
      iconType: 'GENERAL',
      color: 'var(--tag-yellow)',
    },
    ORDER_AMBASSADOR_RECEIVED_ORDER: {
      title: t('OrderReceivedByAmbassador'),
      iconType: 'TRUCK',
      color: 'var(--tag-yellow)',
    },
    APPLICATION_STATUS_UPDATE_MAYBE: {
      title: t('Status Maybe'),
      iconType: 'GENERAL',
      color: 'var(--tag-yellow)',
    },
    APPLICATION_STATUS_UPDATE_CONFIRMED: {
      title: t('Status  Confirmed'),
      iconType: 'GENERAL',
      color: 'var(--tag-green)',
    },
    APPLICATION_STATUS_UPDATE_CONFIRMED_FOR_LATER: {
      title: t('Status Confirmed for later'),
      iconType: 'GENERAL',
      color: 'var(--tag-green)',
    },
    APPLICATION_STATUS_UPDATE_PENDING_CLIENT: {
      title: t('Status Pending client'),
      iconType: 'GENERAL',
      color: 'var(--tag-yellow)',
    },
    APPLICATION_STATUS_UPDATE_PENDING: {
      title: t('Status Pending'),
      iconType: 'GENERAL',
      color: 'var(--tag-yellow)',
    },
    APPLICATION_STATUS_UPDATE_REFUSED: {
      title: t('Status Refused'),
      iconType: 'GENERAL',
      color: 'var(--tag-red)',
    },
    APPLICATION_PROGRESS_UPDATE_TYPEFORM: {
      title: t('Progress typeform'),
      iconType: 'GENERAL',
      color: 'var(--tag-red)',
    },
    APPLICATION_PROGRESS_UPDATE_UPLOAD: {
      title: t('Progress upload'),
      iconType: 'GENERAL',
      color: 'var(--tag-red)',
    },
    APPLICATION_CREATED: {
      title: t('Application created'),
      iconType: 'GENERAL',
      color: 'var(--tag-green)',
    },
    PROJECT_SUBTITLE_PUBLISH: {
      title: t('Subtitles published'),
      iconType: 'CHECK_SQUARE',
      color: 'var(--tag-green)',
    },
    OTHER: {
      title: t('Other'),
      iconType: 'UNKNOWN',
      color: 'var(--black)',
    },
  };

  return eventConfig[eventType] || eventConfig.OTHER;
};

export const projectStatusPresentations = {
  PENDING: (t) => t('The user has sent rushes for this project. You can watch and moderate them, or ask more if needed.'),
  PENDING_RUSHES: (t) =>
    t('Additional rushes were requested, we are waiting for the user to provide them. You can still watch and moderate the existing files.'),
  REFUSED_RUSHES: (t) => t('Rushes were rejected and the user has been notified.'),
  PENDING_EDITING: (t) =>
    t(
      'You can edit the video, either by using the Studio or by manually uploading a final version. If needed, you can consult the project history for feedback from the team.'
    ),
  PENDING_METADATA: (t) =>
    t(
      'You can fill in titles, choose the thumbnail and define the key moments using the cards. If needed, you can consult the project history for feedback from the team.'
    ),
  PENDING_TEAM_VALIDATION: (t) =>
    t('You can do the final validation before the video is published. If the client wishes to validate the video, use this step to do so.'), // t('The video is almost finished, the last step is to watch it again and ensure that the final version is ready to be published.'),
  PENDING_CLIENT_VALIDATION: (t) => t('The project is currently being validated by the client.'),
  VALIDATED: (t) =>
    t(
      'You can choose on which channels to broadcast the video and associate the relevant product references with it. You can also edit your subtitles and access the code snippets required to embed the video on the client website.'
    ),
  PENDING_TRIAGE: (t) => t('The client has rejected the video.'),
  ABANDONNED: (t) => t('The video has been abandoned.'),
};

export const getResources = (t) => ({
  application: t('Application'),
  challenge: t('Challenge'),
  changelog: t('Changelog'),
  comment: t('Comment'),
  company: t('Company'),
  configuration: t('Configuration'),
  contract: t('Contract'),
  event: t('Event'),
  favorites: t('Favorites'),
  input: t('Input'),
  menu: t('Menu'),
  output: t('Output'),
  ownership: t('Ownership'),
  product: t('Product'),
  project: t('Project'),
  resource: t('Resource'),
  route: t('Route'),
  solution: t('Solution'),
  ui: t('UI'),
  user: t('User'),
  webhook: 'Webhook',
});

export const getPolicyRoles = (t) => ({
  ADMIN: {
    name: t('Admin'),
    color: 'var(--tag-red)',
  },
  CUSM: {
    name: t('CUSM'),
    color: 'var(--tag-orange)',
  },
  USM: {
    name: t('USM'),
    color: 'var(--tag-orange)',
  },
  CSM_READONLY: {
    name: t('CSM (readonly)'),
    color: 'var(--tag-orange)',
  },
  VIDEO_EDITOR: {
    name: t('Video editor'),
    color: 'var(--tag-yellow)',
  },
  SUBTITLE_EDITOR: {
    name: t('Subtitle editor'),
    color: 'var(--tag-yellow)',
  },
  VIDEO_VALIDATOR: {
    name: t('Video validator'),
    color: 'var(--tag-yellow)',
  },
  SALES: {
    name: t('Sales representative'),
    color: 'var(--tag-blue)',
  },
  CLIENT: {
    name: t('Client'),
    color: 'var(--tag-green)',
  },
  CLIENT_LIMITED: {
    name: t('ClientLimited'),
    color: 'var(--tag-green)',
  },
  DEFAULT: {
    name: t('Invalid role'),
    color: 'var(--tag-grey)',
  },
});

export const getUserRoles = (t) => ({
  MAINTAINER: {
    name: t('Maintainer'),
    color: 'var(--tag-red)',
  },
  OPERATOR: {
    name: t('Operator'),
    color: 'var(--tag-orange)',
  },
  READER: {
    name: t('Reader'),
    color: 'var(--tag-yellow)',
  },
  DEFAULT: {
    name: t('Invalid role'),
    color: 'var(--tag-grey)',
  },
});

export const getRoles = (t) => ({
  ...getPolicyRoles(t),
  ...getUserRoles(t),
  DEFAULT: {
    name: t('Invalid role'),
    color: 'var(--tag-grey)',
  },
});

export const getUserFeeling = (t) => ({
  GOOD: { label: t('Good'), color: 'var(--tag-green)' },
  BAD: { label: t('Bad'), color: 'var(--tag-red)' },
});

export const getBrowserStatuses = (t) => ({
  RUNNING: {
    name: t('Running'),
    color: 'var(--tag-green)',
  },
  ENDED: {
    name: t('Ended'),
    color: 'var(--tag-grey)',
  },
});

export const getAuditQualities = (t) => ({
  NONE: {
    defaultValue: t('Not plugged'),
    values: {},
    color: 'var(--tag-red)',
  },
  BAD: {
    defaultValue: t('Bad'),
    values: {
      PLAYER: t('iFrame'),
      TRACKING: t('Image'),
      VSG: t('Viewport incorrect'),
    },
    color: 'var(--tag-red)',
  },
  MEDIUM: {
    defaultValue: t('Medium'),
    values: {
      PLAYER: t('JS/DOM'),
    },
    color: 'var(--tag-orange)',
  },
  GOOD: {
    defaultValue: t('Good'),
    values: {
      PLAYER: t('Automatic'),
      TRACKING: t('Automatic'),
      VSG: t('Automatic'),
    },
    color: 'var(--tag-green)',
  },
});

export const getVideoIntegrationTranslation = (t) => ({
  PLUGGED_VALID: {
    color: 'var(--color-success)',
    tagColor: 'var(--tag-green)',
    value: t('Valid video found'),
  },
  PLUGGED_INVALID: {
    color: 'var(--color-warning)',
    tagColor: 'var(--tag-orange)',
    value: t('Invalid video found'),
  },
  PLUGGED_UNKNOWN: {
    color: 'var(--color-warning)',
    tagColor: 'var(--tag-orange)',
    value: t('Video without key found'),
  },
  NOT_PLUGGED: {
    color: 'var(--color-error)',
    tagColor: 'var(--tag-red)',
    value: t('No video found'),
  },
  PAGE_NOT_FOUND: {
    color: 'var(--color-error)',
    tagColor: 'var(--tag-red)',
    value: t('Page not found'),
  },
  FORBIDDEN: {
    color: 'black',
    tagColor: 'var(--tag-grey)',
    value: t('Page restricted'),
  },
  SERVER_DOWN: {
    color: 'black',
    tagColor: 'var(--tag-grey)',
    value: t('Client server down'),
  },
  NO_URL: {
    color: 'var(--color-warning)',
    tagColor: 'var(--tag-orange)',
    value: t('No product URL'),
  },
  INTERNAL_ERROR: {
    color: 'black',
    tagColor: 'var(--tag-grey)',
    value: t('Internal error'),
  },
  DEFAULT: {
    color: 'var(--color-grey)',
    tagColor: 'var(--tag-grey)',
    value: t('Error'),
  },
});

export const getBrowsers = (t) => ({
  chromium: {
    name: t('Chromium'),
    icon: 'CHROME',
  },
});

export const mailsTemplatesNames = {
  CASTING_REMINDER_FORM_1: (t) => t('Form reminder (1/2)'),
  CASTING_REMINDER_FORM_2: (t) => t('Form reminder (2/2)'),
  CASTING_REMINDER_VIDEO_1: (t) => t('Video reminder (1/2)'),
  CASTING_REMINDER_VIDEO_2: (t) => t('Video reminder (2/2)'),
  CASTING_APPLICATION_SENT: (t) => t('Application sent'),
  CASTING_MISSING_DATA: (t) => t('Missing data'), // Not used
  CASTING_APPLICATION_VALIDATED_BASIC: (t) => t('Application validated'),
  CASTING_APPLICATION_VALIDATED_BOOK: (t) => t('Application validated'),
  CASTING_APPLICATION_REFUSED: (t) => t('Application refused'),
  CASTING_APPLICATION_FORLATER: (t) => t('Application forlater'), // Not used
  PLATFORM_WITHOUT_CASTING_SIGN_UP: (t) => t('Sign up'),
  PRODUCT_SELECTION_REMINDER: (t) => t('Product selection reminder'),
  BOOK_ORDER_SENT: (t) => t('Product sent'),
  BOOK_ORDER_REFUSED: (t) => t('Order refused'),
  BOOK_ORDER_NEW: (t) => t('Allow to reorder a product'),
  BOOK_REMINDER_RUSHES_1: (t) => t('Rushes reminder (1/3)'),
  BOOK_REMINDER_RUSHES_2: (t) => t('Rushes reminder (2/3)'),
  BOOK_REMINDER_RUSHES_3: (t) => t('Rushes reminder (3/3)'),
  RUSHES_SENT: (t) => t('Rushes sent'),
  MORE_RUSHES_BOOK_1: (t) => t('More rushes required (1/3)'),
  MORE_RUSHES_BOOK_2: (t) => t('More rushes required (2/3)'),
  MORE_RUSHES_BOOK_3: (t) => t('More rushes required (3/3)'),
  MORE_RUSHES_BASIC_1: (t) => t('More rushes required (1/3)'),
  MORE_RUSHES_BASIC_2: (t) => t('More rushes required (2/3)'),
  MORE_RUSHES_BASIC_3: (t) => t('More rushes required (3/3)'),
  VIDEO_VALIDATED: (t) => t('Video validated'),
  RUSHES_REFUSED: (t) => t('Rushes refused'),
  VIDEO_ABANDONED_BOOK: (t) => t('Video abandoned'),
  VIDEO_ABANDONED_BASIC: (t) => t('Video abandoned'),
  ACCOUNT_RESET_PASSWORD: (t) => t('Reset password'),
  ACCOUNT_DELETED: (t) => t('Account deleted'),
  ACCOUNT_PASSWORD_MODIFIED: (t) => t('Password changed'),
  ACCOUNT_PERSONAL_DATA_MODIFIED: (t) => t('Personal data edited'),
  PLATFORM_REMINDER_RUSHES_WITH_CASTING_1: (t) => t('Rushes reminder (1/3)'),
  PLATFORM_REMINDER_RUSHES_WITH_CASTING_2: (t) => t('Rushes reminder (2/3)'),
  PLATFORM_REMINDER_RUSHES_WITH_CASTING_3: (t) => t('Rushes reminder (3/3)'),
  PLATFORM_REMINDER_RUSHES_WITHOUT_CASTING_1: (t) => t('Rushes reminder (1/3)'),
  PLATFORM_REMINDER_RUSHES_WITHOUT_CASTING_2: (t) => t('Rushes reminder (2/3)'),
  PLATFORM_REMINDER_RUSHES_WITHOUT_CASTING_3: (t) => t('Rushes reminder (3/3)'),
  PLATFORM_FEEDBACK: (t) => t('User feedback'),
  PRODUCT_SELECTION_CONFIRMATION: (t) => t('Product selection confirmation'),
  BOOK_ORDER_CANCELLED: (t) => t('Order cancelled'),
  COMPANY_ONLINE_VIDEOS_AMOUNT_EXCEEDED: (t) => t('Online videos amount exceeded'),
};

export const getLegalDocumentTypes = (t) => ({
  LD: t('Legal Disclaimer'),
  GCU: t('General Conditions of Use'),
  GCS: t('General Conditions of Services'),
  PP: t('Privacy Policy'),
  CTR: t('Conditions for the Transfer of Rights'),
  SLA: t('Service-level Agreement'),
  DPA: t('Data Processing Agreement'),
  TRACKING_OPTIN: t('Optin Tracking Compliance'),
  TRACKING_OPTOUT: t('Optout Tracking Compliance'),
});

export const getTaskDevices = (t) => ({
  DESKTOP: t('Desktop'),
  MOBILE: t('Mobile'),
});

export const getTaskTypes = (t) => ({
  PLAYER: t('Player'),
  VSG: t('VSG'),
  TRACKING: t('Tracking'),
});

export const getAuditStatus = (t) => ({
  AUDITING: t('Auditing'),
  PLUGGED: t('Plugged'),
  NOT_PLUGGED: t('Not plugged'),
  PAGE_NOT_FOUND: t('Page not found'),
  FORBIDDEN: t('Forbidden'),
  SERVER_DOWN: t('Client server down'),
});

export const companyLevels = {
  VIP: (t) => t('COMPANY_LEVEL_VIP'),
  A: (t) => t('COMPANY_LEVEL_A'),
  B: (t) => t('COMPANY_LEVEL_B'),
  C: (t) => t('COMPANY_LEVEL_C'),
  UNKNOWN: (t) => t('Unknown'),
};

export const getOutsourcingTaskTypes = (t) => ({
  VIDEO_EDITING: t('Video editing'),
});

// [WEBHOOK] - Don't forget to update when new event is added, to support all solution type
export const getWebhookTypes = (t) => ({
  casting: {
    APPLICATION_VALIDATED: t('Application validated'),
    APPLICATION_REFUSED: t('Application refused'),
  },
  platform: {
    VIDEO_VALIDATED: t('Video validated'),
    VIDEO_CREATED: t('Video created'),
    VIDEO_VALIDATION_REQUIRED: t('Video validation required'),
    VIDEO_REFERENCES_UPDATED: t('Video references updated'),
    VIDEO_REFUSED: t('Video refused'),
    VIDEO_ABANDONED: t('Video abandoned'),
    VIDEO_DELETED: t('Video deleted'),
    SUBTITLE_CREATED: t('Video subtitle created'),
    SUBTITLE_UPDATED: t('Video subtitle updated'),
    SUBTITLE_DELETED: t('Video subtitle deleted'),
    EXPORTED_VIDEO_CREATED: t('Create exported video'),
  },
  'smart-uploader': {
    VIDEO_VALIDATED: t('Video validated'),
    VIDEO_CREATED: t('Video created'),
    VIDEO_VALIDATION_REQUIRED: t('Video validation required'),
    VIDEO_REFUSED: t('Video refused'),
    VIDEO_ABANDONED: t('Video abandoned'),
    VIDEO_DELETED: t('Video deleted'),
    VIDEO_REFERENCES_UPDATED: t('Video references updated'),
    EXPORTED_VIDEO_CREATED: t('Create exported video'),
    SUBTITLE_CREATED: t('Video subtitle created'),
    SUBTITLE_UPDATED: t('Video subtitle updated'),
    SUBTITLE_DELETED: t('Video subtitle deleted'),
  },
});

export const getQrcodeDestinations = (t) => ({
  PRODUCT_PAGE: t('Product page'),
  VIDEO: t('Product video'),
});

export const getStockStatuses = (t) => ({
  IN_STOCK: {
    color: '--tag-green',
    label: t('In stock'),
  },
  SOLD_OUT: {
    color: '--tag-orange',
    label: t('Sold out'),
  },
});

export const getOrderProblemType = (t) => ({
  WRONG_ADDRESS: t('Wrong address'),
  PRODUCT_EXPECTATION: t('The product was not as expected'),
  PRODUCT_BROKEN: t('Product broken'),
  CUSTOM: t('Custom issues'),
  RECEIVING: t('Conflict if received or not'),
  OTHER: t('Other'),
});
